import { BaseDto } from "@/shared/dtos/base-dto";
import { fichajes_empleado_historial } from "./fichajes_empleado_historial";

export class fichajes_empleado extends BaseDto {
  public fecha_entrada!: Date;
  public fecha_salida!: Date;
  public id_tipo_fichaje!: number;
  public id_usuario!: number;
  public id_centro_trabajo!: number;  
  public modificaciones!: fichajes_empleado_historial[]; 
}
