import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { VariableDto } from '@/shared/dtos/variableDto';

@Module({
    namespaced: true,
    name: 'variablesModule',
    store,
    dynamic: true
})
class variablesModule extends VuexModule {
    public variables: VariableDto[] = [];
    public variable: VariableDto = new VariableDto();

    @Action({ commit: 'onGetVariables' })
    public async getVariables() {
        
        return await ssmHttpService.get(API.variables);
    }

    @Action({ commit: 'onGetVariable' })
    public async getVariable(id: any) {
        return await ssmHttpService.get(`${API.variables}/${id}`);
    }

    @Action
    public async guardarVariable(variable: VariableDto) {
        return await ssmHttpService.post(API.variables, variable.toJson());
    }

    @Action
    public async modificarVariable(variable: VariableDto) {
        return await ssmHttpService.put(`${API.variables}/${variable.id}`, variable);
    }

    @Action
    public async eliminarVariable(variable: VariableDto) {
        return await ssmHttpService.delete(`${API.variables}/${variable.id}`, null, false);
    }

    @Mutation
    public onGetVariables(res: VariableDto[]) {
        this.variables = res ? res.map((x) => new VariableDto(x)) : [];
    }

    @Mutation
    public onGetVariable(res: VariableDto) {
        this.variable = new VariableDto(res);
    }
}

export default getModule(variablesModule);
